<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getBusinessDocumentErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getBusinessDocumentErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('business-document:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'business-document:basicInfo'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-combobox
                                            v-model="
                                                getBusinessDocumentModal.contractor
                                            "
                                            :label="`${$t(
                                                'business-document:contractor'
                                            )}*`"
                                            hint="Zacznij pisać..."
                                            @keyup="
                                                setContacts($event.target.value)
                                            "
                                            @input="assignID()"
                                            :hide-no-data="hideContacts"
                                            :items="getContactsInput.items"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getBusinessDocumentErrors
                                                    .stepOne.fields.contractor
                                            "
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-combobox
                                            :label="`${$t(
                                                'business-document:representative'
                                            )}`"
                                            v-model="
                                                getBusinessDocumentModal.representative
                                            "
                                            @keyup="
                                                setRepresentatives(
                                                    $event.target.value
                                                )
                                            "
                                            :item-text="text"
                                            return-object
                                            append-outer-icon="mdi-account-plus"
                                            item-value="_id"
                                            :items="
                                                getRepresentativeInput.items
                                            "
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template slot="append-outer">
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :close-on-click="false"
                                                    @input="
                                                        v =>
                                                            v ||
                                                            clearMiniModal()
                                                    "
                                                    max-width="300"
                                                    min-width="300"
                                                    origin="center"
                                                    v-model="showMenu"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on: menu,
                                                        }"
                                                    >
                                                        <v-tooltip top>
                                                            <template
                                                                v-slot:activator="{
                                                                    on: tooltip,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    small
                                                                    icon
                                                                    :disabled="
                                                                        getContactsInput
                                                                            .items
                                                                            .length >
                                                                        0
                                                                            ? false
                                                                            : true
                                                                    "
                                                                >
                                                                    <v-icon
                                                                        v-on="{
                                                                            ...menu,
                                                                            ...tooltip,
                                                                        }"
                                                                        @click="
                                                                            showMenu = true
                                                                        "
                                                                        >mdi-plus</v-icon
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'business-document:addRepresentative'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <h3>
                                                                        {{
                                                                            `${$t(
                                                                                'business-document:additionRepresentative'
                                                                            )}`
                                                                        }}
                                                                    </h3>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.name
                                                                        "
                                                                        :label="`${$t(
                                                                            'business-document:representativeName'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.lastname
                                                                        "
                                                                        :label="`${$t(
                                                                            'business-document:representativeLastname'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.email
                                                                        "
                                                                        :label="`${$t(
                                                                            'business-document:representativeEmail'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.phoneNumber
                                                                        "
                                                                        :label="`${$t(
                                                                            'business-document:representativePhoneNumber'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0 d-flex justify-end"
                                                                >
                                                                    <v-btn
                                                                        class="buttons buttons--cancel mr-2"
                                                                        @click="
                                                                            clearMiniModal()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'cancel'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                    <v-btn
                                                                        class="buttons buttons--add"
                                                                        :disabled="
                                                                            disabled
                                                                        "
                                                                        @click="
                                                                            additionRepresentative()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'add'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12"
                                        ><v-text-field
                                            v-model="
                                                getBusinessDocumentModal.title
                                            "
                                            :error-messages="
                                                getBusinessDocumentErrors
                                                    .stepOne.fields.title
                                            "
                                            :label="`${$t(
                                                'business-document:title'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="4">
                                        <v-menu
                                            v-model="expirationDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            :disabled="expirationDateDisabled"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getBusinessDocumentModal.expirationDate
                                                    "
                                                    :error-messages="
                                                        getBusinessDocumentErrors
                                                            .stepOne.fields
                                                            .expirationDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getBusinessDocumentModal.expirationDate = null
                                                    "
                                                    :label="`${$t(
                                                        'business-document:expirationDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getBusinessDocumentModal.expirationDate
                                                "
                                                @input="expirationDate = false"
                                                :disabled="
                                                    expirationDateDisabled
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                            v-model="
                                                getBusinessDocumentModal.indefinite
                                            "
                                            :label="
                                                $t(
                                                    'business-document:indefinite'
                                                )
                                            "
                                            :disabled="indefiniteDisabled"
                                            :error-messages="
                                                getBusinessDocumentErrors
                                                    .stepOne.fields.indefinite
                                            "
                                        ></v-checkbox></v-col
                                ></v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="`${$t(
                                                'business-document:description'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="
                                                getBusinessDocumentModal.description
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
            expirationDate: '',
            indefiniteDisabled: false,
            expirationDateDisabled: false,
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
        ]),
        ...mapMutations(['setContactsInput']),
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        assignID() {
            if (
                this.getBusinessDocumentModal.contractor &&
                this.getBusinessDocumentModal.contractor._id
            ) {
                this.getBusinessDocumentModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getBusinessDocumentModal.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getBusinessDocumentModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
    },
    watch: {
        'getBusinessDocumentModal.expirationDate': function (data) {
            if (data) {
                this.indefiniteDisabled = true
            } else {
                this.indefiniteDisabled = false
            }
        },
        'getBusinessDocumentModal.indefinite': function (data) {
            if (data) {
                this.expirationDateDisabled = true
            } else {
                this.expirationDateDisabled = false
            }
        },
    },
    computed: {
        ...mapGetters([
            'getBusinessDocumentModal',
            'getBusinessDocumentErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
        ]),
    },
    mounted() {
        if (this.getBusinessDocumentModal.indefinite) {
            this.expirationDateDisabled = true
        } else {
            this.expirationDateDisabled = false
        }
        if (this.getBusinessDocumentModal.expirationDate) {
            this.indefiniteDisabled = true
        } else {
            this.indefiniteDisabled = false
        }
    },
}
</script>
